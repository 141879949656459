import {
  Grid,
  Button,
  TextField,
  Pagination,
  Autocomplete,
  CssBaseline,
  Typography,
} from "@mui/material";
import logoFidel from "./../../../img/fidelBlanco.png";
import { useStyles } from "../../Socios/SociosForm/SociosForm.styles";
import { useState, useEffect } from "react";
import Socio from "./Socio";
import {
  getPartners,
  getPartnersTypes,
} from "../../../apiMarketing/services/partners/partners.service";
import { getCitySelect } from "../../../apiMarketing/services/locations/locations.service";
import { Formik } from "formik";
import { errorHandling } from "../../../api";
import "../../../components/Loader/LoaderStyles.css";
import { ColorsCode } from "../../../utils/Colors/ColorsList";
import {
  Partner,
  PartnersRequest,
} from "../../../apiMarketing/services/partners/partners.types";
import { Form } from "react-bootstrap";
import { AsyncSelect, IList } from "../../../common/Selects/AsyncSelect";

export default function SociosForm() {
  document.title = "Socios";
  const classes = useStyles();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [partnersTypes, setPartnersTypes] = useState<IList[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [pages, setPages] = useState<number>(1);

  useEffect(() => loadDataPartners(), []);

  const [filter, setFilter] = useState<PartnersRequest>({
    Page: 1,
    PageSize: 10,
  });

  const search = async () => {
    getPartners(filter)
      .then(({ result, data }) => {
        if (result && data) {
          setPartners(data.results);
          setPages(data.quantity);
        }
      })
      .finally(() => setLoader(false));
  };

  const loadDataPartners = () => {
    Promise.all([getPartners(filter), getPartnersTypes()])
      .then((values) => {
        let partnerResponse = values[0];
        let partnerTypesResponse = values[1];

        if (partnerResponse.result && partnerResponse.data) {
          setPartners(partnerResponse.data.results);
          setPages(partnerResponse.data.quantity);
        }

        if (partnerTypesResponse.result && partnerTypesResponse.data) {
          setPartnersTypes(partnerTypesResponse.data);
        }
      })
      .catch(errorHandling)
      .finally(() => setLoader(false));
  };

  const onSubmit = (values: PartnersRequest) => {
    setLoader(true);
    setFilter({ ...filter, ...values });
  };

  useEffect(() => {
    search();
  }, [filter]);

  const changePage = (Page: number) => setFilter({ ...filter, Page });

  return (
    <>
      <CssBaseline />
      <Grid container width="100%" gridAutoFlow="row">
        <Grid
          container
          item
          order="1"
          height="4rem"
          sx={{ backgroundColor: ColorsCode.green }}
          width="100%"
          justifyContent="center"
          display="flex"
          margin="auto"
        >
          <a href="https://fidel.com.ar" target="_BLANK">
            <img
              src={logoFidel}
              height="60"
              alt=""
              className={classes.imagen}
            />
          </a>
        </Grid>

        <Grid
          order="2"
          className={classes.gridSelect}
          width="100%"
          display="flex"
        >
          <Formik initialValues={filter} onSubmit={onSubmit}>
            {({ setFieldValue, handleSubmit }) => (
              <Form className={classes.formContainer}>
                <div className={classes.selectContainer}>
                  <div className={classes.selectField}>
                    <AsyncSelect
                      label="Ciudad"
                      name="cityId"
                      onLoad={getCitySelect}
                    />
                  </div>

                  <div className={classes.selectField}>
                    <Autocomplete
                      disablePortal
                      id="PartnerTypeId"
                      options={partnersTypes.map(({ text, value }) => ({
                        label: text,
                        value,
                      }))}
                      noOptionsText="Sin coincidencias"
                      className={classes.textField}
                      onChange={(_, newValue) =>
                        setFieldValue("PartnerTypeId", newValue?.value || null)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Rubro" variant="filled" />
                      )}
                    />
                  </div>

                  <div className={classes.selectField}>
                    <TextField
                      id="Text"
                      label="Buscar"
                      variant="filled"
                      className={classes.textField}
                      onChange={(e) => setFieldValue("Text", e.target.value)}
                    />
                  </div>
                </div>
                <Button
                  variant="contained"
                  className={classes.buttonForm}
                  onClick={() => handleSubmit()}
                >
                  Buscar
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
        {!loader ? (
          <>
            <Grid item order="3" width="100%" sx={{ mt: 2 }}>
              {partners.length === 0 && (
                <Grid style={{ justifyContent: "center" }}>
                  <Typography
                    fontSize="16px"
                    textAlign="center"
                    color="#3c453c"
                    fontFamily="sans-serif"
                  >
                    No hay resultados para la búsqueda realizada, revise los
                    campos de búsqueda
                  </Typography>
                </Grid>
              )}
              {partners.map((partner: Partner) => (
                <Socio
                  key={partner.id}
                  name={partner.socialReason}
                  partnerType={partner.partnerType.name}
                  description={partner.description}
                  phoneArea={partner.phoneArea}
                  phoneNumber={partner.phoneNumber}
                  email={partner.email}
                  address={partner.address}
                  web={partner.web || ""}
                  imagen={partner.image}
                />
              ))}
            </Grid>
            <Grid
              item
              order="4"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Pagination
                count={pages}
                shape="rounded"
                sx={{ pb: 3 }}
                onChange={(e, value) => changePage(value)}
              />
            </Grid>
          </>
        ) : (
          <Grid item order="3" width="100%" height="25rem" overflow="hidden">
            <Grid
              className="contLoaderSmall"
              height="inherit"
              overflow="hidden"
            >
              {/* TODO: Reutilizar loader de nico */}
              <b>Cargando...</b>
              <span className="loader"></span>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
