import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useStyles } from "../../pages/Socios/SociosForm/SociosForm.styles";
import { ApiResponse } from "../../apiMarketing/services/types";
import { useFormikContext } from "formik";
import { PartnersRequest } from "../../apiMarketing/services/partners/partners.types";

export type IList = {
  value: number;
  text: string;
};

export interface Props {
  name: "PartnerTypeId" | "cityId" | "Text" | "Page" | "PageSize";
  label: string;
  className?: string;
  onLoad: (InputValue: string) => Promise<ApiResponse<IList[]>>;
}

export const AsyncSelect = ({ name, label, onLoad, className }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly IList[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const { setFieldValue, values } = useFormikContext<PartnersRequest>();

  const [loading, setLoading] = React.useState<boolean>(false);

  const debounceTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const getOptions = async (text: string) => {
    let list: IList[] = [];
    if (text.length >= 3) {
      let { result, data } = await onLoad(text);
      if (result && data) list = data;
      return list;
    } else return [];
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchValue(newValue);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        setLoading(true);
        setOptions([]);
        const response = await getOptions(newValue);
        setSearchValue(newValue);
        setOptions(response);
        setLoading(false);
      } catch (error) {
        console.error("Error al buscar:", error);
      }
    }, 500);
  };

  return (
    <Autocomplete
      id={name}
      className={className || classes.textField}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(_, newValue) => setFieldValue(name, newValue?.value || null)}
      value={options.find((x) => x.value === values[name])}
      isOptionEqualToValue={(option, value) => option.text === value.text}
      getOptionLabel={(option) => option.text}
      options={options}
      loading={loading}
      loadingText={
        loading
          ? "Cargando..."
          : searchValue.length > 3
          ? "Sin coincidencias"
          : "Ingrese como mínimo 3 caracteres..."
      }
      noOptionsText={
        loading
          ? "Cargando..."
          : searchValue.length > 3
          ? "Sin coincidencias"
          : "Ingrese como mínimo 3 caracteres..."
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          onChange={handleSearchChange}
        />
      )}
    />
  );
};
