import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, colors } from '@mui/material';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logoFidel from './../../../img/fidelBlanco.png';
import { useStyles } from './LeadForm.styles';
import { CollaboratorsParams, LeadFormData, SnackBarProps } from './LeadForm.types';
import { getLocalities, getStates } from '../../../api/services/locations/locations.service';
import { Locality, State } from '../../../api/services/locations/locations.types';
import { errorHandling, setTokenVentas } from '../../../api';
import "./../../../components/Background/Background.css";
import { Lead } from '../../../api/services/leads/leads.types';
import { newLead } from '../../../api/services/leads/leads.service';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { initialValues, validationSchema } from './LeadForm.validators';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomTextField } from '../../../common/CustomTextField';
import networkImage from './../../../img/WorldNetwork.png';
import { getCitySelect } from '../../../apiMarketing/services/locations/locations.service';
import { AsyncSelect, IList } from "../../../common/Selects/AsyncSelect";
import styled from '@emotion/styled';

export default function LeadForm() {
  const location = useLocation();
  let params = location.state;
  const classes = useStyles();
  const navigate = useNavigate();

  const [provinceList, setProvinceList] = useState<State[]>([] as State[]);
  const [localitiesList, setLocalitiesList] = useState<Locality[]>([] as Locality[]);
  const [snackbar, setSnackbar] = useState<SnackBarProps>({} as SnackBarProps);
  const [tokenParams, setTokenParams] = useState<CollaboratorsParams>({} as CollaboratorsParams);

  useEffect(() => {
    setTokenVentas(params.token);
    var decoded: any = jwt_decode(params.token);
    setTokenParams({
      token: params.token,
      tokenDni: decoded.DNI,
      tokenEmail: decoded.email,
      tokenName: decoded.name,
    });

    getStates()
      .then(async ({ result, data }) => {
        if (data) {
          data.unshift({ id: 0, name: 'Seleccione Provincia...' });
          setProvinceList(data);
        } else {
          setSnackbar({
            open: true,
            msg: 'Ha ocurrido un error interno',
            severity: 'error',
          });
        }
      })
      .catch(errorHandling);
  }, []);

  const setLocality = (idProvince: number) => {
    getLocalities(idProvince).then(async ({ result, data }) => {
      if (data) {
        data.unshift({ id: 0, name: "Seleccione Localidad...", state: { id: 0, name: "" } })
        setLocalitiesList(data);
      }
    }).catch(errorHandling)
  }

  const onSubmit = (values: LeadFormData) => {
    console.log("Values: ", values);
    const lead: Lead = {
      name: values.name,
      lastName: values.lastName,
      socialReason: values.socialReason,
      phone: values.phone,
      companyEmail: values.email || undefined,
      companyCUIT: values.dni ? +values.dni : undefined,
      cityId: values.cityId ? +values.cityId : undefined,
    };
    
    newLead(lead).then(async (data) => {
      if (data === 400) {
        setSnackbar({ open: true, msg: "El Referido ingresado ya ha sido sugerido anteriormente", severity: "warning" });
      } else if (data === 500) {
        setSnackbar({ open: true, msg: "Ha ocurrido un error interno", severity: "error" });
      } else {
        setSnackbar({ open: true, msg: "Referido creado correctamente", severity: "success" });
      }
    }).catch(errorHandling);
  };

  const handleSnackbar = () => {
    if (snackbar) setSnackbar({} as SnackBarProps)
  }

  const goToProfile = () => {
    let token = params.token;
    navigate("/perfil", { state: { token } });
  }

  const goToList = () => {
    let token = params.token;
    navigate("/referidos", { state: { token } });
  }

  const returnToCredentials = () => {
    navigate("/recomendanos");
  }

  return (
    <Grid container component="main" sx={{ flexDirection: "row" }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={3} square className={`background ${classes.containerForm}`}>
        <Box className={classes.formBox}>
          <div style={{ paddingBottom: "20px" }}>
            <a href="https://fidel.com.ar" target="_BLANK"><img src={logoFidel} height="50" alt="" /></a>
          </div>
          <Paper elevation={0} sx={{ backgroundColor: "rgb(149 195 156)", width: "100%", padding: 1.5, borderRadius: "4px 4px 0 0" }} className={classes.dataCollaborator}>
            <div>
              {tokenParams.tokenName && tokenParams.tokenName.trim() !== "" && (<p className={classes.textName}><b>{tokenParams.tokenName}</b></p>)}
              <p className={classes.textDataCollaborator}><b>Email: </b>{tokenParams.tokenEmail}</p>
              <p className={classes.textDataCollaborator}><b>DNI/CUIT: </b>{tokenParams.tokenDni}</p>
            </div>
            <div className={classes.buttonProfile}>
              <Button size='small' variant="contained" onClick={goToProfile} className={classes.btnLead}>
                Mi Perfil
              </Button>
              <Button size='small' variant="contained" onClick={goToList} className={classes.btnLead}>
                Referidos
              </Button>
            </div>
          </Paper>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Button onClick={returnToCredentials} className={classes.arrowBackButton} sx={{ minWidth: 0, padding: 0, color: "#fff", height: 0 }}><ArrowBackIcon /></Button>
            <Typography component="h1" variant="h5" color={'#fff'}>
              Referí y Ganá
            </Typography>
          </div>
          <Box sx={{ mt: 1 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, handleSubmit }) => (
                <Form>
                  <CustomTextField
                    id="name"
                    name="name"
                    label={
                      <>Nombre del Contacto <span style={{ color: 'red' }}>*</span>
                      </>
                    }
                    error={touched.name && Boolean(errors.name)}
                    autoFocus
                  />
                  <CustomTextField
                    id="lastName"
                    name="lastName"
                    label={
                      <>Apellido del Contacto <span style={{ color: 'red' }}>*</span></>
                    }
                    error={touched.lastName && Boolean(errors.lastName)}
                  />
                  <CustomTextField
                    id="socialReason"
                    name="socialReason"
                    label={
                      <>Empresa / Negocio <span style={{ color: 'red' }}>*</span></>
                    }
                    error={touched.socialReason && Boolean(errors.socialReason)}
                  />
                  <CustomTextField
                    id="phone"
                    name="phone"
                    label={
                      <>Número de Teléfono <span style={{ color: 'red' }}>*</span></>
                    }
                    error={touched.phone && Boolean(errors.phone)}
                  />
                  <AsyncSelect className={classes.location}
                    label="Ciudad"
                    name="cityId"
                    onLoad={getCitySelect}
                  />
                  <CustomTextField
                    id="email"
                    name="email"
                    label="Email"
                    error={touched.email && Boolean(errors.email)}
                  />
                  <CustomTextField
                    id="dni"
                    name="dni"
                    label="DNI o CUIT"
                    error={touched.dni && Boolean(errors.dni)}
                  />
                  <Button
                    //type="submit"
                    //onClick={() => handleSubmit()}
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3, mb: 3, backgroundColor: "#d17a29", '&:hover': {
                        backgroundColor: '#d17a29',
                      }
                    }}
                  >
                    REFERIR
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={false} sm={4} md={7} className={classes.gridContainer} sx={{ flexDirection: "column" }}>
        <article>
          <Typography component="h1" variant="h4" textAlign={"center"} color={'gray'} sx={{ fontWeight: 700, marginTop: 5 }}>
            ¡Sumate a nuestra Red de Influenciadores!
          </Typography>
          <Typography paragraph={true} color={'green'} textAlign={"center"} sx={{ marginTop: 2, fontSize: 19 }}>
            Ayudanos a hacer crecer la comunidad de Fidel y ganá un bono de $10.000... <br /> ¡Sólo tenés que cargar los datos del referido!
          </Typography>
        </article>
        <figure className={classes.contImgNetwork}>
          <img src={networkImage} alt="" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </figure>
      </Grid>
      {snackbar.open && <Snackbar msg={snackbar.msg} severity={snackbar.severity} handleSnack={handleSnackbar} />}
    </Grid>
  );
}