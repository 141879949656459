import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useStyles } from "./CustomTextField.styles";

interface InputProps {
  name: string;
  id: string;
  disabled?: boolean;
  label?: React.ReactNode;
  secureTextEntry?: boolean;
  placeholder?: string;
  value?: string;
  autoCapitalize?: "none" | "words";
  fullWidth?: boolean;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  variant?: "filled" | "outlined" | "standard";
  size?: "small" | "medium";
  margin?: "dense" | "normal" | "none";
  autoFocus?: boolean;
  onChange?: (v: string) => void;
  error?: boolean;
}

export const CustomTextField = (props: InputProps) => {
  const classes = useStyles();

  const {
    name = "",
    id = "",
    disabled = false,
    label,
    placeholder,
    autoCapitalize,
    fullWidth = true,
    size = "small",
    variant = "filled",
    color = "primary",
    margin = "normal",
    autoFocus,
    error = false,
  } = props;
  const { errors, touched, values, handleChange } = useFormikContext<{
    [key: string]: any;
  }>();

  return (
    <>
      <TextField
        disabled={disabled}
        label={label}
        id={id}
        placeholder={placeholder}
        fullWidth={fullWidth}
        size={size}
        variant={variant}
        color={color}
        margin={margin}
        onChange={handleChange}
        value={values[name]}
        autoCapitalize={autoCapitalize}
        autoFocus={autoFocus}
        className={classes.input}
      />
      {error && touched[name] && errors[name] && (
        <span className={classes.errorMessage}>{String(errors[name])}</span>
      )}
    </>
  );
};
