import * as yup from 'yup';
import { LeadFormData } from './LeadForm.types';

export const validationSchema = yup.object({
    name: yup.string().required('Nombre del Contacto requerido'),
    lastName: yup.string().required('Apellido del Contacto requerido'),
    socialReason: yup.string().required('Empresa / Negocio requerido'),
    phone: yup.number().typeError('Sólo se admiten caracteres numéricos').required('Número de teléfono requerido'),
    email: yup.string().email('Ingrese un email válido').nullable(),
    dni: yup.number().typeError('Sólo se admiten caracteres numéricos').nullable(),
    cityId: yup.number().nullable()
});

export const initialValues: LeadFormData = {
    name: '',
    lastName: '',
    socialReason: '',
    email: null,
    phone: '',
    dni: null,
    cityId: null
};
